import Scrollbar from "smooth-scrollbar";
import Snackbar from "node-snackbar";
import Store from "../store/index";
import Route from "../router/index";
import counterUp from "counterup2";
// require("waypoints/lib/noframework.waypoints.min");
export const APPNAME = "GIRA";

export const core = {
  index() {
    // this.loaderInit();
    // this.activeRoute();
    // this.SmoothScrollbar();
    // this.bodyClassCheck();
    // this.ripple();
    // setTimeout(() => {
    //   this.progressBarInit();
    //   this.counterPlgInit();
    // }, 500);
  },
  mainIndex() {
    // this.fixedHeader();
  },
  // bodyClassCheck() {
  //   const body = document.querySelector("body");
  //   const sidebar = document.getElementsByClassName("iq-sidebar");
  //   if (sidebar[0] !== undefined) {
  //     sidebar[0].classList.remove("sidebar-dark-blue");
  //   }
  //   body.classList.remove("icon-with-text");
  //   body.classList.remove("sidebar-main-active");
  //   body.classList.remove("right-column-fixed");
  //   body.classList.remove("two-sidebar");
  //   body.classList.remove("iq-page-menu-horizontal");
  //   body.classList.remove("top-tab-horizontal");
  //   switch (Route.currentRoute.meta.layout) {
  //     case "mini-sidebar-right-fix":
  //       body.classList.add("sidebar-main-active");
  //       body.classList.add("right-column-fixed");
  //       break;
  //     case "nav-with-menu":
  //       body.classList.add("iq-page-menu-horizontal");
  //       break;
  //     case "nav-bottom-menu":
  //       body.classList.add("top-tab-horizontal");
  //       break;
  //     case "two-sidebar":
  //       body.classList.add("two-sidebar");
  //       break;
  //     case "icon-with-text":
  //       body.classList.add("icon-with-text");
  //       sidebar[0].classList.add("sidebar-dark-blue");
  //       break;
  //     default:
  //       break;
  //   }
  // },

  // SmoothScrollbar() {
  //   const elementExistMain = this.checkElement("id", "sidebar-scrollbar");
  //   if (elementExistMain) {
  //     Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
  //   }
  //   const elementExistRight = this.checkElement(
  //     "id",
  //     "right-sidebar-scrollbar"
  //   );
  //   if (elementExistRight) {
  //     Scrollbar.init(document.querySelector("#right-sidebar-scrollbar"));
  //   }
  // },

  // getActiveLink(item, activeRoute) {
  //   let active = false;
  //   if (item.children !== undefined) {
  //     item.children.filter(function(child) {
  //       if (child.link.name === activeRoute) {
  //         active = true;
  //       }
  //     });
  //   } else {
  //     if (item.link) {
  //       if (item.link.name === activeRoute) {
  //         active = true;
  //       }
  //     }
      
  //   }
  //   return active;
  // },
  showSnackbar(type, data = {}) {
    if (type !== null) {
      switch (type) {
        case "success":
          Snackbar.show({ text: data, backgroundColor: "#323232", showAction: true,  actionText: 'Cerrar', width: '200px' });
          break;
        case "error":
          Snackbar.show({
            text: data,
            backgroundColor: "#b21f2d",
            actionTextColor: "#ffffff",
            showAction: true,
            actionText: ' ' + ' ' + '',
            width: 'auto'
            
          });
          break;
      }
    } else {
      Snackbar.show(data);
    }
  },
  Snackbar(data) {
    Snackbar.show(data);
  },
  // random(length) {
  //   let result = "";
  //   const characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   const charactersLength = characters.length;
  //   for (let i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   return result;
  // },
  // applyDrag(arr, dragResult) {
  //   const { removedIndex, addedIndex, payload } = dragResult;
  //   if (removedIndex === null && addedIndex === null) return arr;

  //   const result = [...arr];
  //   let itemToAdd = payload;

  //   if (removedIndex !== null) {
  //     itemToAdd = result.splice(removedIndex, 1)[0];
  //   }

  //   if (addedIndex !== null) {
  //     result.splice(addedIndex, 0, itemToAdd);
  //   }

  //   return result;
  // },
  // generateItems(count, creator) {
  //   const result = [];
  //   for (let i = 0; i < count; i++) {
  //     result.push(creator(i));
  //   }
  //   return result;
  // },
  // progressBarInit() {
  //   let waypointInit;
  //   Array.from(document.getElementsByClassName("iq-progress-bar")).forEach(
  //     pg => {
  //       waypointInit = new Waypoint({
  //         element: pg.childNodes[0],
  //         handler: direction => {
  //           if (direction === "down") {
  //             this.progressDestroy(pg.childNodes[0]);
  //             setTimeout(() => {
  //               this.progressInit(pg.childNodes[0]);
  //             }, 100);
  //           }
  //         },
  //         offset: "bottom-in-view"
  //       });
  //     }
  //   );
  //   return waypointInit;
  // },
  // progressDestroy(e) {
  //   const iqProgress = e;
  //   if (iqProgress !== undefined && iqProgress !== null) {
  //     iqProgress.style.transition = "unset";
  //     iqProgress.style.width = "0%";
  //   }
  // },
  // progressInit(e) {
  //   const iqProgress = e;
  //   if (
  //     iqProgress !== undefined &&
  //     iqProgress !== null &&
  //     iqProgress !== undefined
  //   ) {
  //     iqProgress.style.transition = "width 2s ease 0s";
  //     iqProgress.style.width = iqProgress.getAttribute("data-value") + "%";
  //   }
  // },
  // counterPlgInit() {
  //   let waypointInit;
  //   Array.from(document.getElementsByClassName("counter")).forEach(c => {
  //     waypointInit = new Waypoint({
  //       element: c,
  //       handler: direction => {
  //         if (direction === "down") {
  //           this.counterInit(c);
  //         }
  //       },
  //       offset: "bottom-in-view"
  //     });
  //   });
  //   return waypointInit;
  // },
  triggerSet () {
    const elementExist = this.checkElement('class', 'wrapper-menu')
    if (elementExist) {
      const wrapperMenu = document.querySelector('.wrapper-menu')
      const body = document.querySelector('body')
      this.sidebarMiniSetEvent(wrapperMenu, body)
    }
  },

  sidebarMiniSetEvent (element, target) {
    element.classList.toggle('open')
    target.classList.toggle('sidebar-main')
  },
  counterInit(e) {
    if (e !== undefined && e !== null) {
      counterUp(e, {
        delay: 5,
        time: 100
      });
    }
  },
  checkElement (type, element) {
    let found = false
    let elements
    switch (type) {
      case 'class':
        elements = document.getElementsByClassName(element)
        if (elements !== undefined && elements !== null && elements.length > 0) {
          found = true
        }
        break

      case 'id':
        elements = document.getElementById(element)

        if (elements !== undefined && elements !== null) {
          found = true
        }
        break
    }
    return found
  },
};

export const animation = {
  easing: {
    linear: function(progress) {
      return progress;
    },
    quadratic: function(progress) {
      return Math.pow(progress, 2);
    },
    swing: function(progress) {
      return 0.5 - Math.cos(progress * Math.PI) / 2;
    },
    circ: function(progress) {
      return 1 - Math.sin(Math.acos(progress));
    },
    back: function(progress, x) {
      return Math.pow(progress, 2) * ((x + 1) * progress - x);
    },
    bounce: function(progress) {
      // eslint-disable-next-line no-unused-vars
      for (let a = 0, b = 1, result; 1; a += b, b /= 2) {
        if (progress >= (7 - 4 * a) / 11) {
          return (
            -Math.pow((11 - 6 * a - 11 * progress) / 4, 2) + Math.pow(b, 2)
          );
        }
      }
    },
    elastic: function(progress, x) {
      return (
        Math.pow(2, 10 * (progress - 1)) *
        Math.cos(((20 * Math.PI * x) / 3) * progress)
      );
    }
  },
  animate: function(options) {
    const start = new Date();
    const id = setInterval(function() {
      const timePassed = new Date() - start;
      let progress = timePassed / options.duration;
      if (progress > 1) {
        progress = 1;
      }
      options.progress = progress;
      const delta = options.delta(progress);
      options.step(delta);
      if (progress === 1) {
        clearInterval(id);
      }
    }, options.delay || 10);
  },
  fadeOut: function(element, options) {
    const to = 1;
    this.animate({
      duration: options.duration,
      delta: function(progress) {
        progress = this.progress;
        return animation.easing.swing(progress);
      },
      step: function(delta) {
        if (element) {
          element.style.opacity = to - delta;
        }
      }
    });
  },
  fadeIn: function(element, options) {
    const to = 0;
    this.animate({
      duration: options.duration,
      delta: function(progress) {
        progress = this.progress;
        return animation.easing.swing(progress);
      },
      step: function(delta) {
        element.style.opacity = to + delta;
      }
    });
  }
};

// export const flatpickerSetting = {
//   wrap: true,
//   altFormat: "j M Y",
//   altInput: true,
//   dateFormat: "Y-m-d"
// };
