import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    // LIST COEMTARIES
    async listComentaries({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/commentaries?model=${payload.model}&model_id=${payload.model_id}`)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE COMENTARIES
    async storeComentaries({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post('/api/commentaries', payload)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // UPDATE COMENTARIES
    async showComentaries({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/commentaries/${id}`)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // DESTROY COMENTARIES
    async destroyComentaries({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/commentaries/${id}`, {
          _method: 'DELETE'
        })
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
