import csrf from '@/apis/Csrf'
import axios from 'axios'

export default {
  namespaced: true,

  state: {
    profile: null,
    userProfile: null
  },

  getters: {
    getProfile: state => state.profile,
    getUserProfile: state => state.userProfile
  },

  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = profile.data
    },
    SET_USER_PROFILE(state, profile) {
      state.userProfile = profile.data
    }
  },

  actions: {
    // User
    async fetchUserProfile({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {

        const response = await axios.get('/api/user/profile')
        commit('SET_PROFILE', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // Update Profile
    async updateUserProfile({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await axios.post('/api/user/profile/edit', {
          _method: 'PUT',
          ...payload
        })

        if (payload.password && payload.password_confirmation) {
          const date = { password: payload.password, password_confirmation: payload.password_confirmation }
          await axios.post('/api/user/profile/update/password', {
            _method: 'PUT',
            ...date
          })
        }
        commit('SET_USER_PROFILE', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
