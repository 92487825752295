import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    listArea: []
  },

  getters: {
    getAreaList: state => state.listArea
  },

  mutations: {
    SET_AREA(state, areas) {
      areas.unshift({ id: 1, name: '' })
      state.listArea = areas
    },
    SET_NEW_AREA(state, payload) {
      state.listArea.unshift(payload)
    },
    SET_UPDATE_AREA(state, payload) {
      state.listArea.unshift(payload)
    },
    SET_DESTROY_AREA(state, payload) {
      state.listArea = state.listArea.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST AREA
    async listArea({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/admin/areas')
        commit('SET_AREA', response.data.data)
        return response.data.data
      } catch (err) {
        console.log(err)
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }

    },
    // CREATE AREA AND UPDATE
    async storeArea({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (!payload.id) {
          const response = await Api.post('/api/admin/areas', payload);
          commit("SET_NEW_AREA", response.data.data);
          return 'Registro Guardado';
        } else {
          const response = await Api.post(`/api/admin/areas/${payload.id}`, {
            _method: 'PUT',
            ...payload
          })
          dispatch('listArea')
          return 'Registro editado'
        }
      } catch (err) {
        console.log(err)
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // DESTROY AREA
    async destroyArea({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        await Api.post(`/api/admin/areas/${id}`, {
          _method: 'DELETE'
        })
        commit("SET_DESTROY_AREA", id);
        return 'Registro eliminado'
      } catch (err) {
        console.log(err)
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
