const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Profile View */
const Profile = () => import('../views/User/Profile/Profile')
const ProfileEdit = () => import('../views/User/Profile/ProfileEdit')

const profileChildRoute = prop => [
  {
    path: 'user',
    name: prop + '.user',
    meta: { auth: true, name: 'Perfil' },
    component: Profile
  },
  {
    path: 'profile-edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Editar Perfil' },
    component: ProfileEdit
  }
]

export default [
  {
    path: '/profile',
    name: 'profile',
    component: VerticleLayout,
    meta: { auth: true },
    children: profileChildRoute('profile')
  }
]
