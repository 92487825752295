import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Provedores View */
const ListProvedores = () => import('../views/Admin/Providers/ListProvedores.vue')
const RegisterProvedores = () => import('../views/Admin/Providers/RegisterProvedores.vue')
const ShowProviders = () => import('../views/Admin/Providers/forms/ShowProviders.vue')
const EditProviders = () => import('../views/Admin/Providers/forms/EditProviders.vue')

const provedoresRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListProvedores
  },
  {
    path: 'register',
    name: prop + '.register',
    meta: { auth: true, name: 'Registrar' },
    component: RegisterProvedores
  },
  {
    path: 'show/:id',
    name: prop + '.show',
    meta: { auth: true, name: 'Detalles' },
    component: ShowProviders
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Editar' },
    component: EditProviders
  }
]

export default [
  {
    path: '/provedores',
    name: 'provedores',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('provider.view')
      return check ? next() : next('/pages/error/403')
    },
    children: provedoresRoutes('provedores')
  }
]
