import Api from 'axios'

export default {
  namespaced: true,

  state: {
    payment: []
  },

  getters: {
    getPayment: state => state.payment
  },

  mutations: {
    SET_PAYMENT (state, payload) {
      payload.unshift({id:1, name: ''})
      state.payment = payload
    },
    SET_NEW_PAYMENT(state, payload) {
      state.payment.unshift(payload)
    },
    DESTROY_PAYMENT (state, payload) {
      state.payment = state.payment.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST PAYMENT
    async listPayment ({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.get('/api/admin/payment_types')
        commit('SET_PAYMENT', response.data.data)
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE PAYMENT
    async storePayment ({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        if(!payload.id){
          const response = await Api.post('/api/admin/payment_types', payload)
          commit('SET_NEW_PAYMENT', response.data.data)
          return 'Registro Guardado'
        }else{
        const response = await Api.post(`/api/admin/payment_types/${payload.id}`, {
          _method: 'PUT',
          ...payload
        })
        dispatch('listPayment')
        return 'Registro Editado'
      }
    }catch(err){

    }finally{
      commit('SET_OVERLAY', false, { root: true })
    }
    },
    //DESTROY PAYMENT
    async destroyPayment({commit}, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.post(`/api/admin/payment_types/${payload}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_PAYMENT', payload)
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
