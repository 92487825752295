const VerticleLayout = () => import('@/layouts/VerticleLayout')

/* Area View */
const ListArea = () => import('@/views/Admin/Area/ListArea.vue')

const areaRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListArea
  }
]

export default [
  {
    path: '/area',
    name: 'area',
    component: VerticleLayout,
    meta: { auth: true },
    children: areaRoutes('area')
  }
]
