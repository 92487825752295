const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Category View */
const ListCategory = () => import('../views/Admin/Category/ListCategory.vue')

const categoryRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListCategory
  }
]

export default [
  {
    path: '/categories',
    name: 'categories',
    component: VerticleLayout,
    meta: { auth: true },
    children: categoryRoutes('categories')
  }
]
