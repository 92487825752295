import axios from 'axios'
import helpers from "@/Utils/helpers";

const api = axios
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_URL) {
  api.defaults.baseURL = process.env.VUE_APP_API_URL
} else {
  api.defaults.baseURL = 'http://localhost:8000'
}

axios.interceptors.response.use((response) => {
  if (response.config.parse) {
    // console.log(response)
  }
  return response;
}, (error) => {
  // console.log(error.response)
  helpers.handleError(error);
  return;
});

api.defaults.withCredentials = true
export default api
