import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* PurchaseRequest View */
const ListPurchase = () => import('../views/Admin/PurchaseRequest/List.vue')
const RegisterPurchase = () => import('../views/Admin/PurchaseRequest/Register.vue')
const ShowPurchase = () => import('../views/Admin/PurchaseRequest/Show.vue')

const purchaseRequestRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListPurchase
  },
  {
    path: 'register',
    name: prop + '.register',
    meta: { auth: true, name: 'Registrar' },
    component: RegisterPurchase
  },
  {
    path: 'show/:id',
    name: prop + '.show',
    meta: { auth: true, name: 'Detalles' },
    component: ShowPurchase
  }
]

export default [
  {
    path: '/purchase_requests',
    name: 'purchase_requests',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('purchase.request.view')
      return check ? next() : next('/pages/error/403')
    },
    children: purchaseRequestRoutes('purchase_requests')
  }
]
