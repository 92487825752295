import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    listLocation: []
  },

  getters: {
    getUbication: state => state.listLocation
  },

  mutations: {
    SET_UBICATION(state, payload) {
      payload.unshift({ id: 1, name: '' })
      state.listLocation = payload.filter((item) => item.name != "Área de Recepción");
    },
    SET_NEW_UBICATION(state, payload) {
      state.listLocation.unshift(payload)
    },
    DESTROY_UBICATION(state, payload) {
      state.listLocation = state.listLocation.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST UBICATION
    async listUbication({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/admin/location')
        commit('SET_UBICATION', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE UBICATION
    async storeUbication({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (!payload.id) {
          const response = await Api.post('/api/admin/location', payload)
          commit('SET_NEW_UBICATION', response.data.data)
          return 'Registro agregado'
        } else {
          const response = await Api.post(`/api/admin/location/${payload.id}`, {
            _method: 'PUT',
            ...payload
          })
          dispatch('listUbication')
          return 'Registro editado'

        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }

    },
    // DESTROY UBICATION
    async destroyUbication({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/location/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_UBICATION', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
