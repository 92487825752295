const VerticleLayout = () => import('../layouts/VerticleLayout')

// PAYMENTS TYPES
const listPayment = () => import('../views/Admin/PaymentTypes/listPayment.vue')

const paymentsRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Pagos' },
    component: listPayment
  }
]

export default [
  {
    path: '/payments',
    name: 'payments',
    component: VerticleLayout,
    meta: { auth: true },
    children: paymentsRoutes('payments')
  }
]
