import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    withdrawal: [],
    withdrawalEgreso: [],
    withdrawalPending: [],
    withdrawalPassed: [],
    withdrawalRejected: [],
  },

  getters: {
    getWithdrawal: state => state.withdrawal,
    getWithdrawalEgreso: state => state.withdrawalEgreso,
    getwithdrawalPending: state => state.withdrawalPending,
    getwithdrawalPassed: state => state.withdrawalPassed,
    getithdrawalRejected: state => state.withdrawalRejected,
  },

  mutations: {
    SET_WITHDRAWAL(state, payload) {
      //LISTANDO TODOS
      state.withdrawal = payload
      state.withdrawalEgreso = payload.filter((item) => item.status === 1)
      state.withdrawalRejected = payload.filter((item) => item.status === 0)
      state.withdrawalPending = payload.filter((item) => item.status === 1)
      state.withdrawalPassed = payload.filter((item) => item.status === 2)
    },
    SET_WITHDRAWAL_EGRESS(state, payload) {
      let i = state.withdrawalPending.findIndex(item => item.id === payload.id)
      let vm = state.withdrawalPending.splice(i, 1);
      if (payload.status === 2) {
        vm[0].status = 2
        vm[0].status_text = 'Aprobada'
        state.withdrawalPassed.unshift(vm[0])
      } else {
        vm[0].status = 0
        vm[0].status_text = 'Rechazada'
        state.withdrawalRejected.unshift(vm[0])
      }
    }
  },

  actions: {
    // LIST WITHDRAWAL
    async listWithdrawal({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/product_withdrawal_request')
        commit('SET_WITHDRAWAL', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE WITHDRAWAL
    async storeWithdrawal({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const userForm = new FormData();
        userForm.append("file_request", payload.file_request);
        userForm.append("description", payload.description);
        payload.product_responsables.forEach((e, index) => {
          userForm.append(`product_responsables[${index}]`, e);
        });
        const response = await Api.post('/api/product_withdrawal_request', userForm)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // UPDATE WITHDRAWAL
    async updateWithdrawal({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/product_withdrawal_request/${payload.id}`, {
          _method: 'PATCH',
          status: payload.status
        })
        commit('SET_WITHDRAWAL_EGRESS', payload)
        dispatch('listWithdrawal')

        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
