import Vue from "vue";
import store from "@/store";

const verifyPermission = (el, binding) => {
    let permission = store.getters["auth/getPermissionsUser"];
    for (let i = 0; i < permission.length; i++) {
        if (permission[i] === binding.value) {
           return el.style.display = "inline";
            break;
        } else {
            el.style.display = "none";
        }
    }
};
Vue.directive("can", {
    inserted: function(el, binding) {
        store.watch(
            (state, getters) => getters["auth/getPermissionsUser"],
            newValue => {
                verifyPermission(el, binding);
            }
        );
        verifyPermission(el, binding);
    }
});
 