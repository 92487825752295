import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('file', {
	message: (campo, argumentos) => {
		return "El campo " + campo + " solo acepta documentos con extención .pdf";
	},
	validate: (valor, argumentos) => {
		return argumentos.every(argumento => valor.type.includes(argumento))
	},
});

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('es', es);
