const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Locations View */
const ListUbication = () => import('../views/Admin/Locations/ListUbication.vue')

const ubicationRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListUbication
  }
]

export default [
  {
    path: '/ubication',
    name: 'ubication',
    component: VerticleLayout,
    meta: { auth: true },
    children: ubicationRoutes('ubication')
  }
]
