import axios from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    user: null,
    permits: null
  },

  getters: {
    user: state => state.user,
    check: state => state.user !== null && state.user !== undefined,
    getPermissionsUser: state => state.permits
  },

  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    LOGOUT (state) {
      state.user = null
    },
    SET_PERMITS (state, permits) {
      state.permits = permits
    }
  },

  actions: {
    async fetchUser ({ commit, dispatch }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const data = await axios.get('/api/user')
        commit('SET_USER', data.data)
        commit('SET_PERMITS', data.data.data.permissions)
        return data
      } catch (error) {
        commit('SET_USER', null)
        return error.response
      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    async logout ({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const data = await axios.post('/api/logout')
        return data
      } catch (error) {
        commit('SET_USER', null)
        return error.response.data
      }finally{
      commit('SET_OVERLAY', true, { root: true })
      }
    }
  }
}
