import Api from 'axios'
import csrf from '@/apis/Csrf'
import helpers from "../../Utils/helpers";

export default {
  namespaced: true,

  state: {
    ordersList: [],
    ordersRequest: null,
    ordersLength: null,
    orderCreate: ""
  },

  getters: {
    getOrdersRequest: state => state.ordersRequest,
    getListOrders: state => state.ordersList,
    getOrdersLength: state => state.ordersLength,
    getLisOrderCreate: state => state.orderCreate

  },

  mutations: {
    SET_ORDERS_REQUEST(state, payload) {
      state.orderCreate = payload.filter(item => item.status === 1).length
      state.ordersList = payload
      state.ordersLength = payload.length

    },
    SHOW_ORDERS_REQUEST(state, payload) {
      state.ordersRequest = payload
    },
    DESTROY_ORDERS_REQUEST(state, payload) {
      state.ordersList = state.ordersList.filter(item => item.id !== payload)
    },
    UPDATE_SHOW(state, payload) {
      state.ordersRequest.provider.business_name = payload.providers_name
    }
  },

  actions: {
    // ORDERS_REQUEST LIST
    async listPurchaseOrders({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/purchase_orders')
        commit('SET_ORDERS_REQUEST', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //ORDERS_REQUEST STORE
    async storePurchaseOrders({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (payload.id) {
          const response = await Api.post(`/api/purchase_orders/${payload.id}?attach=1`, {
            _method: 'PATCH',
            ...payload
          });
          commit('UPDATE_SHOW', payload)
          return response.data.data;
        } else {
          const response = await Api.post("/api/purchase_orders", payload);
          return response.data.data;
        }
      } catch (err) {
        helpers.handleError(err);
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // ORDERS_REQUEST SHOW
    async showPurchaseOrders({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/purchase_orders/${id}`)
        commit('SHOW_ORDERS_REQUEST', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //PRODUCTS UPDATE
    async updatePurchaseOrders({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/purchase_orders/${payload.id}`, {
          _method: 'PATCH',
          ...payload
        });
        dispatch('showPurchaseOrders', payload.id)
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async addProductsPurchaseOrders({ dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/purchase_orders/${payload.id}?attach=1`, {
          _method: 'PATCH',
          ...payload
        });
        dispatch('showPurchaseOrders', payload.id)
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // ORDERS_REQUEST DESTROY
    async destroyPurchaseOrders({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/purchase_orders/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_ORDERS_REQUEST', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
