import store from '@/store'
import csrf from './Csrf'

export default async (to, from, next) => {
  if (!store.getters['auth/check']) {
    try {
      await csrf.getCookie()
      const me = await store.dispatch('auth/fetchUser')
      if (me.status === 401) {
        next('auth/login')
      }
    } catch (e) {}
  }
  next()
}
