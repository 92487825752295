const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')

/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/SignIn1')
const RecoverPassword = () => import('../views/AuthPages/ForgotPassword')
const PasswordReset = () => import('../views/AuthPages/RestorePassword')

const authChildRoutes = prop => [
  {
    path: 'login',
    name: prop + '.login',
    meta: { auth: false },
    component: SignIn1
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { auth: false },
    component: RecoverPassword
  }
]
const passwordRoutes = prop => [
  {
    path: 'reset',
    name: prop + '.reset',
    meta: { auth: false },
    component: PasswordReset
  }
]

export default [
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/password',
    name: 'password',
    component: AuthLayout,
    meta: { auth: true },
    children: passwordRoutes('password')
  }
]
