import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    listProviders: null,
    detailsProvider: null
  },

  getters: {
    getProviders: state => state.listProviders,
    getProvider: state => state.detailsProvider,
    getloading: state => state.loading
  },

  mutations: {
    SET_PROVIDERS(state, payload) {
      state.listProviders = payload.data
    },
    SET_NEW_PROVIDERS(state, payload) {
      state.listProviders.unshift(payload)
    },
    SHOW_PROVIDERS(state, payload) {
      state.detailsProvider = payload
    },
    DESTROY_PROVIDERS(state, payload) {
      state.listProviders = state.listProviders.filter(item => item.id !== payload)
    },
  },

  actions: {
    // PROVIDERS LIST
    async fechProviders({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get("/api/admin/provider");
        commit("SET_PROVIDERS", response.data);
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PROVIDERS SHOW
    async showProviders({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/admin/provider/${payload}`)
        commit('SHOW_PROVIDERS', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //PROVIDERS STORE
    async registerProviders({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (!payload.id) {
          const response = await Api.post('/api/admin/provider', payload);
          commit("SET_NEW_PROVIDERS", response.data.data);
          return 'Registro Guardado';
        } else {
          const response = await Api.put(`/api/admin/provider/${payload.id}`, {
            _method: 'PUT',
            ...payload
          })
          return 'Registro actualizado'
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PROVIDERS UPDATE
    async updateProviders({ commit }, payload) {
      await csrf.getCookie()
      commit('loadinData', true)

      commit('loadinData', false)
      return response.data.data
    },
    // PROVIDERS DESTROY
    async destroyProviders({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/provider/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_PROVIDERS', item)
        return 'Registro eliminado'
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
