import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    reception: [],
    receptionLength: null
  },

  getters: {
    getReception: state => state.reception,
    getRecepLength: state => state.receptionLength

  },

  mutations: {
    SET_RECEPTION(state, payload) {
      state.reception = payload
      state.receptionLength = payload.length
    }
  },

  actions: {
    // LIST RECEPTION
    async listReception({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/purchase_order_receipts')
        commit('SET_RECEPTION', response.data.data)
        return response.data.data
      }
      catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // SHOW RECEPTION
    async showReception({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.get(`/api/purchase_order_receipts/${item}`);
        return response.data.data;
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE RECEPTION
    async storeReception({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.post('/api/purchase_order_receipts', payload);
        return response.data.data;
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE RECEPTION
    async storeReception({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.post('/api/purchase_order_receipts', payload)
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },

    // DESTROY AREA
    async destroyArea({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.post(`/api/admin/areas/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_AREA', item)
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
