import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    category: []
  },

  getters: {
    getCategory: state => state.category
  },

  mutations: {
    SET_CATEGORY(state, category) {
      state.category = category.data
    },
    SET_NEW_CATEGORY(state, payload) {
      state.category.unshift(payload)
    },
    DESTROY_CATEGORY(state, payload) {
      state.category = state.category.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST CATEGORIES
    async listCategory({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/admin/categories')
        commit('SET_CATEGORY', response.data)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE CATEGORY
    async storeCategory({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const storeUrl = '/api/admin/category/products'
        const updateUrl = '/api/admin/category'
        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        if (!payload.id) {
          if (!payload.image) {
            let form = { name: payload.name }
            const response = await Api.post(storeUrl, form)
            commit('SET_NEW_CATEGORY', response.data.data)
            return 'Registro editado'
          } else {
            const userForm = new FormData()
            for (const key in payload) {
              userForm.append(key, payload[key])
            }
            await csrf.getCookie();
            const response = await Api.post(storeUrl, userForm, config)
            commit('SET_NEW_CATEGORY', response.data.data)
            return 'Registro agregado'
          }
        } else {
          if (!payload.image) {

            let form = { name: payload.name, type: 1 }
            const response = await Api.put(`${updateUrl}/${payload.id}`, {
              ...form
            })
            dispatch('listCategory')
            return 'Registro editado'
          } else {

            let form = {
              id: payload.id,
              image: payload.image,
              name: payload.name,
              type: 1,
              _method: 'PUT',
            }
            const userForm = new FormData()
            for (const key in form) {
              userForm.append(key, form[key])
            }
            const response = await Api.post(`${updateUrl}/${payload.id}`, userForm, config)
            dispatch('listCategory')
            return 'Registro editado'
          }
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async destroyCategory({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {

        const response = await Api.post(`/api/admin/category/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_CATEGORY', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })

      }
    }
  }
}
