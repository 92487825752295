<template>
  <div>
    <spinner />
    <router-view />
  </div>
</template>
<script>
import { core } from "./config/pluginInit";
import Spinner from "./components/core/loader/Spinner.vue";
export default {
  name: "App",
  components: {
    Spinner,
  },
  mounted() {
    core.mainIndex();
  },
};
</script>
<style lang="scss">
@import "assets/scss/style.scss";

.vs__clear {
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
.custom-file-input:lang(sp) ~ .custom-file-label::after {
  content: "O";
}
</style>
<style>
.swal2-styled {
  margin: 5px !important;
  border-radius: 7px !important;
}
</style>