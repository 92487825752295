import Vue from 'vue'
import Vuex from 'vuex'
import auth from './Auth/auth'
import providers from './Admin/providers'
import tool from './Tools/tool'
import profile from './User/profile'
import user from './Admin/user'
import products from './Admin/products'
import category from './Admin/category'
import purchase from './Admin/purchase'
import area from './Admin/area'
import permissions from './Admin/permissions'
import orders from './Admin/orders'
import quotations from './Admin/quotations'
import brand from './Admin/brands'
import ubication from './Admin/ubication'
import payments from './Admin/payments'
import withdrawal from './Admin/withdrawal'
import comentaries from './Admin/comentaries'
import reception from './Admin/reception'
import help from './Help/help'
const data = require('../../package.json');
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    providers,
    products,
    tool,
    profile,
    user,
    category,
    purchase,
    area,
    permissions,
    orders,
    quotations,
    brand,
    ubication,
    payments,
    withdrawal,
    comentaries,
    reception,
    help
  },
  state: {
    loading: false,
    breadcrumb: [],
    packageVersion: data.version || 0,
    
  },
  mutations: {
    run (state, payload) {
      state.loading = true
    },
    stop (state, payload) {
      state.loading = false
    },
    SET_OVERLAY(state, payload){
      state.loading = payload
    },
    SET_BREADCRUMB (state, payload){
      state.breadcrumb = payload
    },
    SET_VERSION(state, payload){
      
    },
  },
  actions: {},

  getters: {
    run: state => state.loading,
    getBreadcrumb: state => state.breadcrumb,
    getVersion : state => state.packageVersion,
    
  },
  strict: debug
})
