import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import check from '@/apis/Check'
import AuthRequired from "../Utils/AuthRequired";


import ProvidersRoute from './ProvidersRoute'
import ProductsRoute from './ProductsRoute'
import AreaRoute from './AreaRoute'
import CategoryRoute from './CategoryRoute'
import LocationsRoute from './LocationsRoute'
import OrdersRoute from './OrdersRoute'
import RequestRoute from './RequestRoute'
import ReceptionRoute from './ReceptionRoute'
import PaymentsRoute from './PaymentsRoute'
import BrandsRoute from './BrandRoute'
import AuthRoute from './AuthRoute'
import ProfileRoute from './ProfileRoute'
import UsersRoute from './UsersRoute'
import HelpRoute from './HelpRouter'

/* Layouts */
const VerticleLayout = () => import('../layouts/VerticleLayout')
const Default = () => import('../layouts/BlankLayout')

/* Dashboards View */
const Dashboard2 = () => import('../views/Dashboard/Index.vue')

/* Error Pages */
const ErrorPage = () => import('../views/Pages/Index')

Vue.use(VueRouter)

const pagesChildRoutes = prop => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { auth: false },
    component: ErrorPage
  }
]

const routes = [
  {
    path: '',
    name: 'dashboard',
    component: VerticleLayout,
    beforeEnter: AuthRequired,
    meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'home',
        component: Dashboard2,
        meta: { auth: true }
      }
    ]
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default')
  },
  {
    path: '*',
    redirect: '/pages/error/404'
  },
  ...ProvidersRoute,
  ...ProductsRoute,
  ...AreaRoute,
  ...CategoryRoute,
  ...LocationsRoute,
  ...OrdersRoute,
  ...ReceptionRoute,
  ...RequestRoute,
  ...PaymentsRoute,
  ...BrandsRoute,
  ...AuthRoute,
  ...ProfileRoute,
  ...UsersRoute,
  ...HelpRoute
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.getters['auth/check'] && to.name === 'purchase_requests.show') {
        await store.dispatch('auth/fetchUser')
        if (!store.getters['auth/check']) return router.push({ path: '/auth/login', query: { purchase_request: to.path }})
        else next()
    }
    if (!store.getters['auth/check'] && to.path !== '/auth/login' && to.path !== '/auth/password-reset' && to.path !== '/password/reset') {
      const user = check(to, from, next)
      if (!user) {
        next('/auth/login')
      }
    } else if (store.getters['auth/check'] && to.path === '/auth/login') {
      next('/')
    } else {
      next()
    }
  } else {
    return next()
  }
})

export default router
