import axios from 'axios'

export default {
  namespaced: true,

  state: {
    comunas: null,
    roles: null,
    users: null
  },

  getters: {
    getComunas: state => state.comunas,
    getRoles: state => state.roles,
    getUsers: state => state.users

  },

  mutations: {
    SET_COMUNAS(state, comunas) {
      state.comunas = comunas.data
    },
    SET_ROLES(state, roles) {
      state.roles = roles
    },
    SET_USERS(state, payload) {
      state.users = payload
    },
  },

  actions: {
    async fetchComunas({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await axios.get('/api/tools/comunas')
        commit('SET_COMUNAS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async fetchRoles({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await axios.get('/api/roles?name=!super-admin,representative')
        commit('SET_ROLES', response.data)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async fetchUsers({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await axios.get('/api/users/slim')
        commit('SET_USERS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
