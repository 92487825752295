import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Products View */
const ListProducts = () => import('../views/Admin/Products/ListProducts.vue')
const RegisterProducts = () => import('../views/Admin/Products/RegisterProducts.vue')
const ShowProducts = () => import('../views/Admin/Products/showProducts.vue')
const EditProducts = () => import('../views/Admin/Products/EditProducts.vue')

/* Stock Products View */
const ListProductsStock = () => import('../views/Admin/StockProducts/StockProducts.vue')
const DetailsStock = () => import('../views/Admin/StockProducts/DetailsStock.vue')
const registerStock = () => import('../views/Admin/StockProducts/RegisterStockProducts.vue')

// Answerable View
const answerable = () => import('../views/Admin/Answerable/index.vue')

// Movimenst Products
const movientStock = () => import('../views/Admin/MovientStock/List.vue')

const productsRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListProducts
  },
  {
    path: 'register',
    name: prop + '.register',
    meta: { auth: true, name: 'Registrar' },
    component: RegisterProducts
  },
  {
    path: 'show/:id',
    name: prop + '.show',
    meta: { auth: true, name: 'Detalles' },
    component: ShowProducts
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Editar' },
    component: EditProducts
  },
  {
    path: 'stock/',
    name: prop + '.stock',
    meta: { auth: true, name: 'Stock' },
    component: ListProductsStock
  },
  {
    path: 'stock/details/:id',
    name: prop + '.stock/details',
    meta: { auth: true, name: 'Detalles' },
    component: DetailsStock
  },
  {
    path: 'stock-register/',
    name: prop + '.stock-register',
    meta: { auth: true, name: 'Stock Registro' },
    component: registerStock
  },
  {
    path: 'answerable',
    name: prop + '.answerable',
    meta: { auth: true, name: 'Responsable' },
    component: answerable
  },
  {
    path: 'moviment',
    name: prop + '.moviment',
    meta: { auth: true, name: 'Movimiento Stock' },
    component: movientStock
  }
]
export default [
  {
    path: '/products',
    name: 'products',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('products.view')
      return check ? next() : next('/pages/error/403')
    },
    children: productsRoutes('products')
  }
]
