import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* User View */
const AddUser = () => import('../views/Admin/Users/AddUser')
const UserList = () => import('../views/Admin/Users/UserList')
const EditUser = () => import('../views/Admin/Users/EditUser')
const ShowUser = () => import('../views/Admin/Users/ShowUser')

// Permissions Users
const Permissions = () => import('../views/Admin/Permissions/Permissions')

const usersChildRoute = prop => [
  {
    path: 'register',
    name: prop + '.register',
    meta: { auth: true, name: 'Agregar Usuario' },
    component: AddUser
  },
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado de Usuarios' },
    component: UserList
  },
  {
    path: 'edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Editar Usuario' },
    component: EditUser
  },
  {
    path: 'show/:id',
    name: prop + '.show',
    meta: { auth: true, name: 'Detalles de Usuarios' },
    component: ShowUser
  },
  {
    path: 'permissions/:id',
    name: prop + '.permissions',
    meta: { auth: true, name: 'Asignación De Permisos' },
    component: Permissions
  }
]

export default [
  {
    path: '/users',
    name: 'users',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('users.view')
      return check ? next() : next('/pages/error/403')
    },
    children: usersChildRoute('users')
  }
]
