import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./Utils/fliter";
import "./plugins";
import "./directives";
import "./directives/permissions.js";
import TheMask from 'vue-the-mask'

Vue.config.productionTip = false;
Vue.use(TheMask);

const vm = new Vue({
  router,
  store, 
  i18n,
  render: h => h(App)
}).$mount("#app");

window.vm = vm;
