import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Purchase Orders View */
const ListOrders = () => import('../views/Admin/OrdersRequest/ListOrders.vue')
const RegisterOrders = () => import('../views/Admin/OrdersRequest/RegisterOrders.vue')
const ShowOrders = () => import('../views/Admin/OrdersRequest/ShowOrders.vue')

const ordersRequestRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListOrders
  },
  {
    path: 'register',
    name: prop + '.register',
    meta: { auth: true, name: 'Registrar' },
    component: RegisterOrders
  },
  {
    path: 'show/:id',
    name: prop + '.show',
    meta: { auth: true, name: 'Detalles' },
    component: ShowOrders
  }
]

export default [
  {
    path: '/orders_requests',
    name: 'orders_requests',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('purchase.order.view')
      return check ? next() : next('/pages/error/403')
    },
    children: ordersRequestRoutes('orders_requests')
  }
]
