import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    brand: []
  },

  getters: {
    getBrands: state => state.brand
  },

  mutations: {
    SET_BRAND(state, brand) {
      state.brand = brand.data
    },
    SET_NEW_BRAND(state, payload) {
      state.brand.unshift(payload)
    },
    DESTROY_BRAND(state, payload) {
      state.brand = state.brand.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST BRAND
    async listBrand({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (payload) {
          const response = await Api.get(`/api/admin/brands/?per_page=${payload.perPage}`)
          commit('SET_BRAND', response.data)
          return response.data
        } else {
          const response = await Api.get('/api/admin/brands')
          commit('SET_BRAND', response.data)
          return response.data.data
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })

      }
    },
    // CREATE BRAND
    async storeBrand({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })

      try {

        if (!payload.id) {
          const data = new FormData();
          data.append("id", payload.id);
          data.append("name", payload.name);
          if (payload.image) {
            data.append("image", payload.image);
          }
          const response = await Api.post('/api/admin/brands', data)
          commit('SET_NEW_BRAND', response.data.data)
          return "Registro agregado"

        } else {
          const data = new FormData();
          data.append("id", payload.id);
          data.append("name", payload.name);
          data.append("_method", 'PUT');
          if (payload.image) {
            data.append("image", payload.image);
          }

          await Api.post(`/api/admin/brands/${payload.id}`, data)
          dispatch('listBrand')
          return 'Redistro editado'
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // DESTROY BRAND
    async destroyBrand({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/brands/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_BRAND', item)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })

      }
    },

  }
}
