import Api from 'axios'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    // LIST PERMISSIONS
    async listPermissions ({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.get('/api/admin/permissions')
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    // LIST PERMISSIONS FROM USER
    async listPermissionsUser ({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.get(`/api/admin/permissions/user/${id}`)
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    },
    // SYNC PERMISSIONS FOR USER
    async syncPermissionsUser ({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try{
        const response = await Api.post(`/api/admin/permission/sync/${payload.id}`, {
          _method: 'PUT',
          ...payload
        })
        return response.data.data
      }catch(err){

      }finally{
      commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
