import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog';

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

// Tell Vue to install the plugin.
Vue.use(VuejsDialog, {
    okText: 'Continuar',
    cancelText: 'Cerrar',
    message: '¿Esta Seguro de Eliminar Este Registro?',
})