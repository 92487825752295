const VerticleLayout = () => import('@/layouts/VerticleLayout')
// PurchaseReception
const ListReception = () => import("../views/Admin/PurchaseReception/List.vue");
const ShowReception = () => import("../views/Admin/PurchaseReception/Show.vue");
const DetailsReception = () => import("../views/Admin/PurchaseReception/details.vue");


const purchaseReception = prop => [
    {
      path: "list",
      name: prop + ".list",
      meta: { auth: true, name: "Listado" },
      component: ListReception
    },
    {
      path: "show/:id",
      name: prop + ".show",
      meta: { auth: true, name: "Detalles" },
      component: ShowReception
    },
    {
      path: "details/:id",
      name: prop + ".details",
      meta: { auth: true, name: "Detalles" },
      component: DetailsReception
    },
  ];
  export default [
    {
        path: "/purchase_reception",
        name: "purchase_reception",
        component: VerticleLayout,
        meta: { auth: true },
        // async beforeEnter (to, from, next) {
        //   const check = await checkPermission('purchase.order.view')
        //   return check ? next() : next('/pages/error/403')
        // },
        children: purchaseReception("purchase_reception")
      },
  ]

