import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    products: [],
    product: {},
    stock: [],
    detaills: {},
    record: {},
    productLength: null,
    recordTable: [],
    answerable: [],
    recalledProducts: [],
    listMovement: [],
    listMovementPending: [],
    listMovementPassed: [],
    listMovementRejected: [],

  },

  getters: {
    getProducts: state => state.products,
    getProduct: state => state.product,
    getStock: state => state.stock,
    getDetaills: state => state.detaills,
    getRecord: state => state.record,
    getLength: state => state.productLength,
    getRecordTable: state => state.recordTable,
    getAnswerable: state => state.answerable,
    getRecalled: state => state.recalledProducts,
    getListMovement: state => state.listMovement,
    getMovemenPending: state => state.listMovementPending,
    getMovemenPassed: state => state.listMovementPassed,
    getMovemenRejected: state => state.listMovementRejected


  },

  mutations: {
    SET_PRODUCTS(state, payload) {
      state.products = payload.data
      state.productLength = payload.data.length
    },
    SET_NEW_PRODUCT(state, payload) {
      state.products.unshift(payload)
    },
    SHOW_PRODUCT(state, payload) {
      state.product = payload
    },
    DESTROY_PRODUCTS(state, payload) {
      state.products = state.products.filter(item => item.id !== payload)
    },
    SET_PRODUCTS_STOCK(state, payload) {
      state.stock = payload.data
    },
    SET_PRODUCTS_STOCK_DETAILL(state, payload) {
      state.detaills = payload;
    },
    SET_PRODUCTS_STOCK_DETAILL(state, payload) {
      state.detaills = payload
    },
    SET_RECORD_STOCK(state, payload) {
      state.record = payload
    },
    SET_RECORD_TABLE(state, payload) {
      state.recordTable = payload
    },
    SET_LIST_ANSWERABLE(state, payload) {
      state.recalledProducts = payload.filter((item) => item.status === 2);
      state.answerable = payload.filter((item) => item.status !== 2)
    },
    SET_CHANGE_STATUS_ANSWERABLE(state, payload) {
      let i = state.answerable.findIndex(item => item.code === payload.code)
      if (payload.status === 0) {
        state.answerable[i].status = 3
        state.answerable[i].status_text = "Solicitud de Baja Rechada"
      } else {
        state.answerable[i].status = 2
        state.answerable[i].status_text = "Solicitud de Baja Aprobada"
        state.recalledProducts.unshift(state.answerable[i])
        state.answerable.splice(i, 1)
      }

    },
    SET_UPDATE_ANSWERABLE(state, payload) {
      let item = state.answerable.findIndex(item => item.id === payload.id)
      state.answerable[item].responsable.id = payload.user.id
      state.answerable[item].responsable.name = payload.user.fullname
    },
    SET_LIST_MOVEMENT(state, payload) {
      state.listMovementRejected = payload.filter((item) => item.status === 0);
      state.listMovementPending = payload.filter((item) => item.status === 1);
      state.listMovementPassed = payload.filter((item) => item.status === 2);
    },
    SET_UPDATE_MOVEMENT(state, payload) {
      let i = state.listMovementPending.findIndex(item => item.id === payload.id)
      let vm = state.listMovementPending.splice(i, 1);
      if (payload.status === 2) {
        vm[0].status = 2
        vm[0].status_text = 'Aprobada'
        state.listMovementPassed.unshift(vm[0])
      } else {
        vm[0].status = 0
        vm[0].status_text = 'Rechazada'
        state.listMovementRejected.unshift(vm[0])
      }
    }
  },

  actions: {
    // PRODUCTS LIST
    async listProducts({ commit }, { search }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const url = search
          ? `/api/admin/products?search=${search}`
          : '/api/admin/products'
        const response = await Api.get(url)
        commit('SET_PRODUCTS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PRODUCTS SHOW
    async showProducts({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/admin/product/${id}`)
        commit('SHOW_PRODUCT', response.data.data)
        return response.data.data
      } catch (err) {
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // GET SKU PRODUCT
    async skuProducts({ commit }, payload) {
      try {
        if (!payload) {
          const response = await Api.get(`/api/admin/products/sku`)
          return response.data.sku
        } else {
          if (!payload.id) {
            const response = await Api.get(`/api/admin/products/sku?sku=${payload.sku}`)
            return response.data.sku
          } else {
            const response = await Api.get(`/api/admin/products/sku?sku=${payload.sku}&prod=3`)
            return response.data.sku
          }
        }
      } catch (err) {

      }
    },
    // GET SKU PRODUCT
    async nameProducts({ commit }, payload) {
      try {
        const response = await Api.get(`/api/admin/products/search/name?search=${payload}`)
        return response.data
      } catch (err) {

      }
    },
    // PRODUCTS STORE
    async storeProducts({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (!payload.id) {
          const response = await Api.post('/api/admin/products', payload)
          commit('SET_NEW_PRODUCT', response.data.data)
          return { message: 'Producto agregado', data: response.data.data }
        } else {
          const response = await Api.put(`/api/admin/product/${payload.id}`, {
            _method: 'PUT',
            ...payload
          })
          dispatch('listProducts')
          return 'Registro editado'
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PRODUCTS UPDATE
    async updateProviders({ commit }, payload) {


    },
    // PRODUCTS DESTROY
    async destroyProducts({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/product/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_PRODUCTS', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCTS LIST STOK
    async listProductsStock({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (payload.type === 1) {
          const url = payload.search
            ? `/api/admin/product/stock?paginated=true&page=${payload.currentPage}&per_page=${payload.perPage}&search=${payload.search}`
            : `/api/admin/product/stock?paginated=true&page=${payload.currentPage}&per_page=${payload.perPage}`;
          const response = await Api.get(url);
          commit('SET_PRODUCTS_STOCK', response)
          return response.data
        } else {
          const response = await Api.get(`/api/admin/product/stock?paginated=true&page=${payload.currentPage}&per_page=${payload.perPage}${payload.ubicacion ? '&location=' + payload.ubicacion : ''}${payload.area ? '&area=' + payload.area : ''}${payload.search ? '&search=' + payload.search : ''}`)
          commit('SET_PRODUCTS_STOCK', response)
          return response.data
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCTS STOCK STORE
    async storeStockProducts({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post('/api/admin/product/stock', payload)
        // dispatch('listProductsStock', 1)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCTS STOCK PRODUCT
    async productRecord({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const ingreso = await Api.get(`/api/admin/product/stock/record/${payload}?type=1`)
        const egreso = await Api.get(`/api/admin/product/stock/record/${payload}?type=2`)
        const table = await Api.get(`/api/admin/product/stock/record/${payload}/table`)
        let response = {
          ingreso: ingreso.data.data,
          egreso: egreso.data.data
        }
        commit('SET_RECORD_TABLE', table.data)
        commit('SET_RECORD_STOCK', response)
        return response
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCT ANSWERABLE LIST
    async listAnswerable({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/admin/products/responsable_list')
        commit('SET_LIST_ANSWERABLE', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCTS ANSWERABLE
    async updateAnswerable({ dispatch, commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.patch(`/api/admin/product/responsable/${payload.id}`, {
          user: payload.user.id
        })
        commit('SET_UPDATE_ANSWERABLE', payload)
        // dispatch('listAnswerable')
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // PRODUCT STOCK MOVEMENT
    async movementStock({ dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/product/stock/${payload.id}/movement`, payload)
        dispatch('productRecord', payload.id)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PRODUCT MOVEMENT STOCK ANSWERABLE LIST
    async movementStockList({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/product_movement_request')
        commit('SET_LIST_MOVEMENT', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PRODUCT MOVEMENT STOCK ANSWERABLE STORE
    async movementStockAnswerable({ dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/product_movement_request`, payload);
        dispatch('movementStockList')
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //PRODUCT MOVEMENT STOCK ANSWERABLE UPDATE
    async updateMovement({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.patch(`/api/product_movement_request/${payload.id}`, {
          status: payload.status
        });
        commit('SET_UPDATE_MOVEMENT', payload)
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async productRegister({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/admin/product/stock/${payload.id}/balance?location=${payload.location}&area=${payload.area}`)
        return response.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
  }
}
