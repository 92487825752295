import Api from 'axios'
import csrf from '@/apis/Csrf'
import helpers from "../../Utils/helpers";
import swal from "sweetalert2";

export default {
  namespaced: true,

  state: {
    purchaseRequests: null,
    purchaseRequest: null,
    purchaseLength: null,
    purchaseCreate: null
  },

  getters: {
    getPurchaseRequests: state => state.purchaseRequests,
    getPurchaseRequest: state => state.purchaseRequest,
    getPurchaseLength: state => state.purchaseLength,
    getPurchaseCreate: state => state.purchaseCreate
  },

  mutations: {
    SET_PURCHASE_REQUESTS(state, payload) {
      let i = 0
      payload.map((item) => {
        if (item.status === 1) {
          i = i + 1
        }
      })
      state.purchaseCreate = i
      state.purchaseRequests = payload
      state.purchaseLength = payload.length
    },
    SET_NEW_PURCHASE(state, payload) {
      state.purchaseRequests.unshift(payload)
    },
    SHOW_PURCHASE_REQUEST(state, payload) {
      state.purchaseRequest = payload
    },
    DESTROY_PURCHASE_REQUEST(state, payload) {
      state.purchaseRequests = state.purchaseRequests.filter(item => item.id !== payload)
    },
    APPROVED_PURCHASE_REQUEST(state, payload) {
      state.purchaseRequest.status = payload.status
      state.purchaseRequest.status_description = payload.status_text
    }
  },

  actions: {
    // PURCHASE_REQUESTS LIST
    async listPurchaseRequests({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/purchase_requests')
        commit('SET_PURCHASE_REQUESTS', response.data.data)
        return response.data.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PURCHASE REQUESTS STORE
    async storePurchaseRequest({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const config = { header: { 'Content-Type': 'multipart/form-data' } }
        // REGISTRO DE SOLICITUD DE COMPRA
        const response = await Api.post('/api/purchase_requests', payload)

        if (payload.file !== null) {
          /*  const dataQuotations = {
              description: payload.description,
              file: payload.file,
              payment_type: payload.payment_type
            }
            const userForm = new FormData()*/
          const dataQuotations = new FormData();
          dataQuotations.append("description", payload.description ? payload.description : '');
          dataQuotations.append("file", payload.file ? payload.file : '');
          dataQuotations.append("payment_type", payload.payment_type ? payload.payment_type : '');
          const res = await Api.post(`/api/admin/purchase/${response.data.data.id}/quotations?type=request`, dataQuotations, config)
        }
        commit("SET_NEW_PURCHASE", response.data.data);
        return response.data.data
      } catch (error) {
        console.error(error);
        helpers.handleError(error);
        throw error;
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CHANGE STATUS PURCHASE REQUESTS
    async changeStatusPurchaseRequest({ commit }, { id, status }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.patch(`/api/purchase_requests/${id}`, { status: status })
        return response.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })

      }
    },
    // PURCHASE REQUESTS SHOW
    async showPurchaseRequest({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/purchase_requests/${id}`)
        commit('SHOW_PURCHASE_REQUEST', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PURCHASE REQUESTS DESTROY
    async destroyPurchaseRequests({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/purchase_requests/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_PURCHASE_REQUEST', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // PURCHASE REQUESTS APPROVED
    async approvedPurchaseRequest({ commit, dispatch }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        let res;
        if (payload.status === 6) {
          await Api.put(`/api/purchase_requests/${payload.id}/send-to-approve`)
          res = { status: 6, status_text: 'Pendiente de Aprobación' }
        } else if (payload.status === 2) {
          await Api.put(`/api/purchase_requests/${payload.id}/approved`)
          res = { status: 2, status_text: 'Aprobada' }
        }
        if (payload.type === 1) {
          dispatch('listPurchaseRequests')
        } else if (payload.type == 2) {
          commit('APPROVED_PURCHASE_REQUEST', res)
        }
        if (res) {
          swal.fire({
            title: `Atención`,
            text: res.status === 6 ? 'Solicitud de aprobación enviada' : res.status === 2 ? 'Solicitud aprobada' : null,
            icon: "success",
            showConfirmButton: false,
            timer: 2000
          });
        }
        return res
      } catch (err) {
        helpers.handleError(err);
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }

    },
    async resendMailPurchase({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (id) {
          const res = await Api.post(`/api/purchase_requests/${id}/resend-to-approve`)
          if (res) {
            swal.fire({
              title: `Atención`,
              text: res.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 2000
            });
          }
        }
      } catch (err) {
        helpers.handleError(err);
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  }
}
