import vue from "vue";
import swal from "sweetalert2";
import { core } from '@/config/pluginInit'
// import "sweetalert2/src/sweetalert2.scss";

export default {
  handleError(error) {
    if (error.response) {
      const { errors } = error.response.data;
      if (errors) {
        Object.keys(errors).forEach(e => {
          errors[e].forEach(message =>
            core.showSnackbar('error', message)
          );
        });
      } else if (error.response.data) {
        const dataError = error.response.data.data;
        if (dataError) {
          Object.keys(dataError).forEach(e => {
            dataError[e].forEach(message =>
              core.showSnackbar('error', message)
            );
          });
        } else {
          if (error.response.status !== 401) {
            const message = error.response
            swal.fire({
              title: `Atención`,
              text: message,
              icon: "error",
              showConfirmButton: false,
              timer: 3500
            });
          }
        }
      } else {
        swal.fire({
          title: `Atención`,
          text: message,
          icon: "error",
          showConfirmButton: false,
          timer: 3500
        });
      }
      if (error.response.status === 500) {
        swal.fire({
          title: `Atención`,
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 3500
        });
      }
    }
  },
  async confirmAction() {
    const confirm = await swal.fire({
      title: "CONFIRMACIÓN",
      text: "Esta seguro que desea continuar con esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#172b4d",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      return result.isConfirmed
    });
    return confirm
  }
};
