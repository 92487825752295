import store from "@/store";

export default async (permission) => {
	let permit = store.getters["auth/getPermissionsUser"];
	let check;
    for (let i = 0; i < permit.length; i++) {
        if (permit[i] === permission && permit[i] !== undefined) {
           check = permit[i];
           break;
        } else {
           check = false;
        }
    }
    return check;

};
