import axios from 'axios'

export default {
  namespaced: true,

  state: {
    listVideos: null
  },

  getters: {
    getVideos: state => state.listVideos
  },

  mutations: {
    SET_VIDEOS(state, payload) {
      state.listVideos = payload.data
    }
  },

  actions: {
    // LIST VIDEO TUTORIALS
    async get({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await axios.get('/api/video-tutorials')
        commit('SET_VIDEOS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
  }
}
