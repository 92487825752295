import csrf from '@/apis/Csrf'
import Api from 'axios'

export default {
  namespaced: true,

  state: {
    users: null,
    user: ''
  },

  getters: {
    getUsers: state => state.users,
    getUser: state => state.user
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users.data
    },
    SHOW_USER(state, payload) {
      state.user = payload
    },
    DESTROY_USERS(state, users) {
      state.users = state.users.filter(item => item.id !== item)
    }
  },

  actions: {
    // User list
    async fetchUsers({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {

        await csrf.getCookie()
        const response = await Api.get('/api/admin/users')
        commit('SET_USERS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // User Register
    async addUser({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        await csrf.getCookie()
        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        const response = await Api.post('/api/admin/users', payload, config)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    async showUser({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get(`/api/admin/user/${id}`)
        commit('SHOW_USER', response.data.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //Update Users
    async updateUsers({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {

        if (!payload.avatar) {
          const response = await Api.post(`/api/admin/user/${payload.id}`, payload);
          return response.data.data;
        } else {
          const config = {
            header: {
              'Content-Type': 'multipart/form-data; boundary=${form._boundary}'
            }
          };
          let userForm = new FormData();
          for (let key in payload) {
            userForm.append(key, payload[key]);
          }
          const response = await Api.post(`/api/admin/user/${payload.id}`, userForm, config);
          return response.data.data;
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // destroy Users
    async destroyUsers({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`api/admin/user/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_USERS', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // update password Users

    async updatePassword({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.put(`/api/admin/user/${payload.id}/password`, payload)
        return response.data
      } catch (error) {
        console.log(error)
      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    }
  },

}
