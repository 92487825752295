import Api from 'axios'
import csrf from '@/apis/Csrf'

export default {
  namespaced: true,

  state: {
    quotations: null,
    quotation: null
  },

  getters: {
    getArea: state => state.quotations,
    getQuotation: state => state.quotation

  },

  mutations: {
    SET_QUOTATIONS(state, quotations) {
      state.quotations = quotations.data
    },
    SET_QUOTATIONS_PURCHASE(state, payload) {
      state.quotation.unshift(payload)
    },
    SET_QUOTATION(state, payload) {
      state.quotation = payload
    },
    DESTROY_QUOTATIONS(state, payload) {
      state.quotation = state.quotation.filter(item => item.id !== payload)
    }
  },

  actions: {
    // LIST QUOTATIONS
    async listQuotations({ commit }) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.get('/api/admin/purchase/quotations')
        commit('SET_QUOTATIONS', response.data)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // LIST QUOTATIONS IN PURCHASE
    async listQuotationsInPurchase({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        if (payload.type) {
          const response = await Api.get(`/api/admin/purchase/${payload.id}/quotations?type=${payload.type}`)
          commit('SET_QUOTATION', response.data.data)
          return response.data.data
        } else {
          const response = await Api.get(`/api/admin/purchase/${payload.id}/quotations`)
          commit('SET_QUOTATION', response.data.data)
          return response.data.data
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // CREATE QUOTATIONS
    async storeQuotations({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const config = { header: { 'Content-Type': 'multipart/form-data' } }
        const userForm = new FormData();
        for (const key in payload.form) {
          userForm.append(key, payload.form[key]);
        }
        if (payload.type === 'order') {
          // COTIZACIONES DESDE ORDENES DE COMPRAS
          const response = await Api.post(`/api/admin/purchase/${payload.id}/quotations`, userForm, config)
          return response.data.data
        } else if (payload.type === 'request') {
          // COTIZACIONES DESDE SOLICITUDES DE COMPRAS
          const res = await Api.post(`/api/admin/purchase/${payload.id}/quotations?type=request`, userForm, config)
          commit('SET_QUOTATIONS_PURCHASE', res.data.data)
          return res.data.data
        }
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

    // UPDATE QUOTATIONS
    async editQuotations({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/purchase/quotations/${payload.id}`, {
          _method: 'PUT',
          ...payload
        })
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    async updateQuotations({ commit }, form) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/purchase/quotations/${form.id}`, {
          _method: 'PUT',
          ...form
        })
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // DESTROY QUOTATIONS
    async destroyQuotations({ commit }, item) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/purchase/quotations/${item}`, {
          _method: 'DELETE'
        })
        commit('DESTROY_QUOTATIONS', item)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    //APROBATE QUOTATIONS
    async aprobateQuotations({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.post(`/api/admin/purchase/quotations/${id}/approved`, {
          _method: "PUT",
        });
        return response.data.data;
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // CREATE QUOTATIONS IVOICE
    async storeInvoice({ commit }, payload) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        const response = await Api.post(`/api/admin/purchase/quotations/${payload.id}/upload/invoice`, payload.form, config)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },
    // DELETE QUOTATIONS IVOICE
    async deleteInvoice({ commit }, id) {
      commit('SET_OVERLAY', true, { root: true })
      try {
        const response = await Api.delete(`/api/admin/purchase/quotations/${id}/delete/invoice`)
        return response.data.data
      } catch (err) {

      } finally {
        commit('SET_OVERLAY', false, { root: true })
      }
    },

  }
}
