import checkPermission from '@/apis/Check-permission'
const VerticleLayout = () => import('../layouts/VerticleLayout')

/* Ubication View */
const ListBrand = () => import('../views/Admin/Brands/ListBrand.vue')

const brandsRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: ListBrand
  }
]

export default [
  {
    path: '/brands',
    name: 'brands',
    component: VerticleLayout,
    meta: { auth: true },
    async beforeEnter (to, from, next) {
      const check = await checkPermission('brands.view')
      return check ? next() : next('/pages/error/403')
    },
    children: brandsRoutes('brands')
  }
]
