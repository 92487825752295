const VerticleLayout = () => import('@/layouts/VerticleLayout')

/* Area View */
const HelpRoute = () => import('@/views/Help/index.vue')

const helpRoutes = prop => [
  {
    path: 'list',
    name: prop + '.list',
    meta: { auth: true, name: 'Listado' },
    component: HelpRoute
  }
]

export default [
  {
    path: '/help',
    name: 'help',
    component: VerticleLayout,
    meta: { auth: true },
    children: helpRoutes('help')
  }
]
